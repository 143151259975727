import React from "react";
import { useMediaQuery } from "@material-ui/core";

export default function PagePadding({children}) {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <div style={{ paddingLeft: matches ? 40 : 20, paddingRight: matches ? 40 : 20, marginTop: 20 }}>
      {children}
    </div>
  );
}