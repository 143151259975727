import React, { useState } from "react"
import LayoutLogged from "../../components/layout/logged"
import Container from '@material-ui/core/Container';
import { useQueryParam } from "use-query-params";
import ApiLoading from "../../components/data/api-loading";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  TextField,
  Button,
  ButtonGroup,
  IconButton,
  CircularProgress
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import api from "../../services/api";
import { ifNotHaveAccessRedirect } from "../../services/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function ConfigOptionManager() {
  const [id] = useQueryParam("id");
  const [option, setOption] = useState();
  const [dropDisabled, setDropDisabled] = useState(false);
  const [values, setValues] = useState([]);

  const classes = useStyles();

  if (!id) return <></>;

  const handleAddNew = () => {
    setValues([...values, {
      id: Date.now().toString(),
      name: '',
      order: values.length,
      local: true,
      deleted_at: null
    }]);
  };

  const handleChangeValue = (item, event) => {
    item.editing = event.target.value;
    setDropDisabled(true);

    if (item.editing === item.name) {
      handleCancel(item);
    }
  };

  const handleKeyDown = (item, event) => {
    if (event.key === 'Enter') {
      handleSave(item);
    }
  }

  const handleSave = async (item) => {
    if (!item.editing) return;

    const editing = item.editing;
    const nameBefore = item.name;
    item.name = editing;
    item.saving = true;

    if (item.local) {
      try {
        const response = await api.post(`/option/${id}/value`, item);

        item.id = response.data.id;
        item.local = false;
        item.error = false;
        item.saving = false;
        handleCancel(item);
      } catch (error) {
        updateValues(item, (found) => {
          found.error = JSON.stringify(error.response.data);
          found.editing = editing;
          found.name = nameBefore;
          item.saving = false;
        });
      }
    } else {
      try {
        await api.put(`/option/${id}/value/${item.id}`, item);
        item.error = false;
        item.saving = false;
        handleCancel(item);
      } catch (error) {
        updateValues(item, (found) => {
          found.error = JSON.stringify(error.response.data);
          found.editing = editing;
          found.name = nameBefore;
          item.saving = false;
        });
      }
    }
  };

  const handleCancel = (item) => {
    item.editing = undefined;
    setDropDisabled(false);
  };

  const handleToggle = (value) => () => {
    const isDeleted = value.deleted_at;

    updateValues(value, async(found) => {
      found.saving = true;
      if (!isDeleted) {
        found.deleted_at = Date.now();
      } else {
        found.deleted_at = null;
      }
    });
    
    updateValues(value, async (found) => {
      if (!isDeleted) {
        try {
          await api.delete(`/option/${id}/value/${found.id}`);
          found.deleted_at = Date.now();
        } catch (error) {
          found.error = JSON.stringify(error.response.data);
          found.deleted_at = null;
        }
      } else {
        try {
          await api.put(`/option/${id}/value/${found.id}/restore`);
          found.deleted_at = null;
        } catch (error) {
          found.error = JSON.stringify(error.response.data);
          found.deleted_at = Date.now();
        }
      }

      found.saving = false;
    });
  };

  const updateValues = async(value, fnc) => {
    const result = Array.from(values);
    const found = result.find(element => element.id === value.id);

    if (!found) return;

    await fnc(found);

    setValues(result);
  };

  const onDragEnd = async(result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (values.filter(item => item.local).length > 0) {
      return;
    }

    const items = reorder(
      values,
      result.source.index,
      result.destination.index
    );

    const diff = [];

    items.forEach((item, index) => {
      if (item['order'] !== index) {
        diff.push(item);
      }

      item['order'] = index;
    });

    if (diff.length > 0) {
      api.put(`/option/${id}/value/order`, diff);
    }

    setValues(items);
  }

  if (ifNotHaveAccessRedirect('configuration.write')) return <></>;

  return (
    <LayoutLogged title={option?.name}>
      <Container style={{maxHeight: 500}}>
        <ApiLoading
          api={`/option/${id}?_inactive=1`}
          onLoaded={(option) => {
            setOption(option);
            setValues(option.values);

            if (option.values.length === 0) {
              handleAddNew();
            }
          }}>

          <List subheader={<ListSubheader>Valores da opção</ListSubheader>} className={classes.root}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable isDropDisabled={dropDisabled} droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {values.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ListItem>
                              <ListItemIcon>
                                {!dropDisabled && !item.local && (
                                  <DragIndicatorIcon />
                                )}
                              </ListItemIcon>
                              <ListItemText id="switch-list-label-wifi">
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  placeholder="Valor..."
                                  style={{
                                    width: '95%',
                                  }}
                                  onChange={(event) => handleChangeValue(item, event)}
                                  onKeyDown={(event) => handleKeyDown(item, event)} 
                                  defaultValue={item.name}
                                  InputProps={{
                                    readOnly: item.deleted_at,
                                  }}
                                  error={item.error}
                                  helperText={item.error}
                                />
                              </ListItemText>
                              <ListItemSecondaryAction>
                                {item.saving && (
                                  <CircularProgress color="primary" size={30} />
                                )}

                                {!item.editing && !item.local && !item.saving && (
                                  <Switch
                                    edge="end"
                                    onChange={handleToggle(item)}
                                    checked={!item.deleted_at}
                                  />
                                )}
                                
                                {item.editing && !item.saving && (
                                  <ButtonGroup disableElevation variant="contained" color="primary">
                                    <IconButton onClick={() => handleSave(item)}>
                                      <SaveIcon color="secondary" />
                                    </IconButton>
                                  </ButtonGroup>
                                )}
                              </ListItemSecondaryAction>
                            </ListItem>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

          </List>

          <br/>
          <Button
            variant="text"
            color="primary"
            size="small"
            margin="normal"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddNew}
          >
            Adicionar opção
          </Button>

        </ApiLoading>
      </Container>
    </LayoutLogged>
  );
}