import React from "react";
import Header from "./header";
import { Link, navigate } from 'gatsby';
import { isAuthenticated } from "../../services/auth";
import Theme from "./theme";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Home, NavigateNext } from "@material-ui/icons";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import PagePadding from "./page-padding";

export default function LayoutLogged({
  children,
  title,
  icon,
}) {
  if (!isAuthenticated()) {
    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {
      navigate('/login');
    }
    return (<></>);
  }

  return (
    <Theme>
      <Header title={title} icon={icon} />

      <ModalRoutingContext.Consumer>
        {({ modal }) => (
          <div>
            {(!modal && title) ? (
              <PagePadding>
                <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                  <Link color="primary" to="/"><Home style={{ color: '#000' }} /></Link>
                  <Typography color="textPrimary">{title}</Typography>
                </Breadcrumbs>
              </PagePadding>
            ) : (<></>)}

            {!modal && <div>{children}</div>}
            {modal && <div style={{maxHeight: 600}}>{children}</div>}
          </div>
        )}
      </ModalRoutingContext.Consumer>
    </Theme>
  );
}